<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="constructions.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("LIST.ALL_CONSTRUCTIONS") }}
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("LIST.ALL_CONSTRUCTIONS")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  class="btn btn-light-primary font-weight-bolder"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->

              <!--begin::Button-->
              <router-link
                to="/constructions/new"
                class="btn btn-primary font-weight-bolder"
                ><span class="svg-icon svg-icon-md">
                  <inline-svg src="media/svg/icons/Tools/Hummer.svg" />
                </span>
                {{ $t("LIST.NEW_CONSTRUCTION") }}</router-link
              >
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isConstructionsLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span
                            @click="orderProductsByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              {{ $t("GENERAL.NAME") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 120px">
                          <span
                            @click="orderProductsByPrice"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': priceOrder.isActive }"
                              >{{ $t("GENERAL.START") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': priceOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="priceOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 120px">
                          {{ $t("GENERAL.END") }}
                        </th>
                        <th style="min-width: 120px">
                          {{ $t("GENERAL.PROOF") }}
                        </th>
                        <th style="min-width: 120px">
                          {{ $t("GENERAL.BILL") }}
                        </th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("LIST.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(construction, key) in constructions"
                        :key="key"
                      >
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="'/constructions/edit/' + construction.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ construction.name }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block"
                                >{{ $t("GENERAL.ADDRESS") }}:
                                {{ construction.address }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ construction.started | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ construction.finished | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ construction.proof | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ construction.bill | formatDate }}</span>
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <a
                            href="#"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            @click.prevent="showConstruction(construction.id)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                                title="View"
                                aria-label="View"
                              />
                            </span>
                          </a>
                          <router-link
                            :to="'/constructions/edit/' + construction.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeConstruction(construction.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-primary py-4" role="alert">
          {{ $t("LIST.NO_CONSTRUCTIONS") }}
          <router-link
            to="/constructions/new"
            style="
              color: #ffffff;
              float: right;
              font-weight: 600;
              text-decoration: underline;
            "
            >{{ $t("LIST.ADD_CONSTRUCTION") }}</router-link
          >
        </div>
      </div>
    </div>

    <b-modal id="showModal" hide-header hide-footer size="md" ref="showModal">
      <div v-if="isSelectedConstructionLoaded" class="text-left">
        <div v-if="selectedConstruction.name" class="mb-2">
          <strong>{{ $t("LIST.NAME") }}: </strong>
          <span>{{ selectedConstruction.name }}</span>
        </div>
        <div v-if="selectedConstruction.address" class="mb-2">
          <strong>{{ $t("GENERAL.ADDRESS") }}: </strong
          ><span
            ><a
              :href="
                'https://www.google.com/maps/place/' +
                selectedConstruction.address
              "
              target="_blank"
              >{{ selectedConstruction.address }}</a
            ></span
          >
        </div>
        <div v-if="selectedConstruction.started" class="mb-2">
          <strong>{{ $t("GENERAL.START") }}: </strong
          ><span>{{ selectedConstruction.started | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.finished" class="mb-2">
          <strong>{{ $t("GENERAL.END") }}: </strong
          ><span>{{ selectedConstruction.finished | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.proof" class="mb-2">
          <strong>{{ $t("GENERAL.PROOF") }}: </strong
          ><span>{{ selectedConstruction.proof | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.bill" class="mb-2">
          <strong>{{ $t("GENERAL.BILL") }}: </strong
          ><span>{{ selectedConstruction.bill | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.description" class="mb-2">
          <strong>{{ $t("GENERAL.NOTE") }}: </strong
          ><span>{{ selectedConstruction.description }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ListConstructions",
  data() {
    return {
      constructions: [],
      isConstructionsLoaded: false,
      priceOrder: {
        query: "asc",
        isActive: false,
      },
      nameOrder: {
        query: "asc",
        isActive: false,
      },
      selectedConstruction: null,
      isSelectedConstructionLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_WORKERS"), route: "/constructions/list" },
    ]);

    ApiService.get("constructions").then(({ data }) => {
      this.constructions = data["hydra:member"];
      this.isConstructionsLoaded = true;

      console.log(this.constructions);
    });
  },
  methods: {
    exportXLSX() {
      ApiService.get("constructions/export").then(({ data }) => {
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("CONSTRUCTION.EXPORT"),
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("CONSTRUCTION.EXPORT_ERR"),
          });
        }
      });
    },
    orderProductsByPrice() {
      // this.priceOrder.isActive = true;
      // if (this.priceOrder.query === "asc") {
      //   this.priceOrder.query = "desc";
      // } else {
      //   this.priceOrder.query = "asc";
      // }
      //
      // this.isProductsLoaded = false;
      // ApiService.get(
      //   "/products",
      //   `?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=brand&order[price]=${this.priceOrder.query}`
      // ).then(({ data }) => {
      //   this.products = [];
      //   this.products = [...data["hydra:member"]];
      //   this.isProductsLoaded = true;
      // });
    },
    orderProductsByName() {
      // this.nameOrder.isActive = true;
      // if (this.nameOrder.query === "asc") {
      //   this.nameOrder.query = "desc";
      // } else {
      //   this.nameOrder.query = "asc";
      // }
      //
      // this.isProductsLoaded = false;
      // ApiService.get(
      //   "/products",
      //   `?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=brand&order[name]=${this.nameOrder.query}`
      // ).then(({ data }) => {
      //   this.products = [];
      //   this.products = [...data["hydra:member"]];
      //   this.isProductsLoaded = true;
      // });
    },
    showConstruction(id) {
      this.isSelectedConstructionLoaded = false;
      ApiService.get("constructions/" + id).then(({ data }) => {
        this.selectedConstruction = data;
        this.isSelectedConstructionLoaded = true;
        this.$refs["showModal"].show();
      });
    },
    removeConstruction(id) {
      const confirmation = confirm(this.$t("CONSTRUCTION.DEL_CONFIRM"));
      if (confirmation === false) return;

      ApiService.delete("constructions/" + id).then(({ status }) => {
        if (status === 204) {
          this.constructions = this.constructions.filter(
            (construction) => construction.id !== id
          );
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("CONSTRUCTION.DELETED"),
          });
        }
      });
    },
  },
};
</script>
